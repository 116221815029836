/*
 * 物流相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//物流申请接口
export const insertLogisticsApply = (param) => {
  return axios.post(`/personnelapi/knLogisticsApply/insertLogisticsApply`, param);
}
//物流申请列表接口
export const selectLogisticsByPagination = (param) => {
  return axios.post(`/personnelapi/knLogisticsApply/selectLogisticsByPagination`, param);
}
//报销详情接口
export const selectLogisticsById = (param) => {
  return axios.get(`/personnelapi/knLogisticsApply/selectLogisticsById?id=${param}`);
}
//物流审批接口
export const updateToLogisticsApproval = (param) => {
  return axios.post(`/personnelapi/knLogisticsApply/updateToLogisticsApproval`, param);
}
//物流修改接口
export const updateLogisticsApply = (param) => {
  return axios.post(`/personnelapi/knLogisticsApply/updateLogisticsApply`, param);
}